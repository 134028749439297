<script lang="ts">

	interface Props {
		height?: string; // Default height
		width?: string; // Default width
		class?: string;
	}

	let { height = '48px', width = '48px', class: className = '' }: Props = $props();
	
</script>

<svg
	xmlns="http://www.w3.org/2000/svg"
	fill="none"
	viewBox="0 0 48 48"
	{height}
	{width}
	class={className}
>
	<path
		id="Union"
		fill="currentColor"
		fill-rule="evenodd"
		d="M44.67428571428571 24.082285714285714c2.5817142857142854 -2.5817142857142854 2.993142857142857 -5.941714285714285 2.190857142857143 -9.102857142857141 -0.7885714285714286 -3.12 -2.766857142857143 -6.1817142857142855 -5.214857142857142 -8.629714285714284C39.20228571428571 3.9017142857142852 36.137142857142855 1.926857142857143 33.01714285714286 1.1348571428571428c-3.1542857142857144 -0.8022857142857143 -6.514285714285713 -0.39085714285714285 -9.09942857142857 2.1942857142857144 -2.177142857142857 2.1737142857142855 -3.3188571428571425 5.321142857142857 -3.977142857142857 8.057142857142857a48.288 48.288 0 0 0 -0.9154285714285715 5.2868571428571425l-8.434285714285714 0.576a1.7142857142857142 1.7142857142857142 0 0 0 -1.5428571428571427 2.129142857142857 27.10628571428571 27.10628571428571 0 0 0 19.566857142857142 19.573714285714285 1.7142857142857142 1.7142857142857142 0 0 0 2.1359999999999997 -1.5428571428571427l0.576 -8.434285714285714c1.3885714285714286 -0.17485714285714285 3.322285714285714 -0.43885714285714283 5.290285714285714 -0.9154285714285715 2.729142857142857 -0.6582857142857143 5.88 -1.8 8.057142857142857 -3.977142857142857Zm-33.959999999999994 9.565714285714286a0.8777142857142857 0.8777142857142857 0 0 1 1.2685714285714285 0.04457142857142857c0.7337142857142857 0.8125714285714285 1.5085714285714285 1.5874285714285714 2.3245714285714287 2.321142857142857a0.8777142857142857 0.8777142857142857 0 0 1 0.04457142857142857 1.2685714285714285l-9.19542857142857 9.19542857142857a2.571428571428571 2.571428571428571 0 1 1 -3.6342857142857143 -3.6342857142857143l9.188571428571429 -9.192Z"
		clip-rule="evenodd"
		stroke-width="3.4285714285714284"
	/></svg
>
